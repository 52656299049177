import {
  IParticipantPerformanceInitializer,
  ParticipantPerformance,
} from '../participant-performance/participant-performance';

export interface ICompanyParticipant
  extends IParticipantPerformanceInitializer {
  projectPlanSent: boolean;
  usersCount: number;
}

export class CompanyParticipant extends ParticipantPerformance {
  public projectPlanSent: boolean = false;
  public usersCount: number = 0;

  constructor(iCompanyParticipantInitializer?: Partial<ICompanyParticipant>) {
    super(iCompanyParticipantInitializer);

    if (iCompanyParticipantInitializer) {
      this.usersCount =
        iCompanyParticipantInitializer.usersCount ?? this.usersCount;
      this.projectPlanSent =
        iCompanyParticipantInitializer.projectPlanSent ?? this.projectPlanSent;
    }
  }

  public toObject(): ICompanyParticipant {
    return {
      ...super.toObject(),
      projectPlanSent: this.projectPlanSent,
      usersCount: this.usersCount,
    };
  }
}
