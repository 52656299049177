import {
  CitiesChallenge,
  ICitiesChallengeInitializer,
} from '../../cities-challenge/cities-challenge/cities-challenge.model';

export interface ICompanyCitiesChallengeInitializer
  extends ICitiesChallengeInitializer {
  participantIds: Array<string>;
}

export class CompanyCitiesChallenge extends CitiesChallenge {
  public participantIds: Array<string> = [];

  constructor(
    iCompanyCitiesChallengeInitializer?: Partial<ICompanyCitiesChallengeInitializer>
  ) {
    super(iCompanyCitiesChallengeInitializer);

    if (iCompanyCitiesChallengeInitializer) {
      this.participantIds =
        iCompanyCitiesChallengeInitializer.participantIds ??
        this.participantIds;
    }
  }

  public toObject(): ICompanyCitiesChallengeInitializer {
    const { participantIds } = this;

    return {
      ...super.toObject(),
      participantIds,
    };
  }
}
