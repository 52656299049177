import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

// Models
import {
  CITIES_CHALLENGE_TYPES,
  CitiesChallenge,
} from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { Region } from 'src/app/models/cities-challenge/region/region.model';

// Services
import { AdminService } from 'src/app/services/admin/admin.service';
import { RoutesService } from 'src/app/services/routes/routes.service';
import { CitiesChallengeService } from 'src/app/services/cities-challenge/cities-challenge.service';

// Helpers
import { CITIES_CHALLENGE_ROUTE_MAP_PATH } from 'src/app/constants/constants';
import { Helpers } from 'src/app/helpers/helpers';
import { TranslateService } from '@ngx-translate/core';
import { CitiesChallengePerformance } from 'src/app/models/cities-challenge/cities-challenge-performance/cities-challenge-performance.model';

@Component({
  selector: 'app-edit-cities-challenge',
  templateUrl: './edit-cities-challenge.component.html',
  styleUrls: ['./edit-cities-challenge.component.scss'],
})
export class EditCitiesChallengeComponent implements OnInit {
  public challengeDataForm: UntypedFormGroup;

  private citiesChallengeId: string;
  public companyId: string;
  public citiesChallenge: CitiesChallenge;

  public editableChallenge = true;

  public CITIES_CHALLENGE_TYPES = CITIES_CHALLENGE_TYPES;

  public showUpdatingCitiesChallengeSpinner = false;
  public showLoadingCitiesChallengeSpinner = true;

  private SNACKBAR_TYPES = {
    GET_CITIES_CHALLENGE_FAILURE: 'GET_CITIES_CHALLENGE_FAILURE',
    UPDATE_FAILURE: 'EDIT_CITIES_CHALLENGE.FAILURE_SNACKBAR.UPDATE',
    UPDATE_SUCCESS: 'EDIT_CITIES_CHALLENGE.SUCCESS_SNACKBAR.UPDATE',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private routesService: RoutesService,
    private citiesChallengeService: CitiesChallengeService,
    private translateService: TranslateService
  ) {
    this.challengeDataForm = this.citiesChallengeService.getStandardCitiesChallengeDataForm();
  }

  get defaultRoutes(): Array<Route> {
    return this.adminService.routes;
  }

  get challengeRegions(): FormArray<FormGroup> {
    return this.challengeDataForm.controls.regions as UntypedFormArray;
  }

  public ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');

    this.citiesChallengeId = this.route.snapshot.paramMap.get(
      'citiesChallengeId'
    );

    this.getCitiesChallenge(this.companyId, this.citiesChallengeId);
  }

  public returnPage(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  private getCitiesChallenge(companyId: string, challengeId: string): void {
    this.citiesChallengeService
      .getCompanyCityChallengeFromFirebase(companyId, challengeId)
      .then((citiesChallenge: CitiesChallenge) => {
        this.citiesChallenge = citiesChallenge;
        this.citiesChallengeService.setCitiesChallengeDataFormValues(
          this.challengeDataForm,
          this.citiesChallenge
        );
        this.editableChallenge =
          (this.challengeRegions.at(0).controls.dateRange as UntypedFormGroup)
            .controls.start.value > new Date();

        if (!this.editableChallenge) {
          this.challengeDataForm.disable({ emitEvent: false });
          if (
            (this.challengeRegions.at(this.challengeRegions.length - 1).controls
              .dateRange as UntypedFormGroup).controls.end.value > new Date()
          ) {
            this.challengeDataForm.controls.expectedDailySteps.enable({
              emitEvent: false,
            });
          }
        }
        this.showLoadingCitiesChallengeSpinner = false;
      })
      .catch((error) => {
        this.showSnackBar(
          this.SNACKBAR_TYPES.GET_CITIES_CHALLENGE_FAILURE,
          error
        );
        console.log(
          'citiesChallengeService.getCompanyCityChallengeFromFirebase - error: ',
          error
        );
      });
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }

  private async generateRoute(
    routeDataForm: UntypedFormGroup,
    index = 0
  ): Promise<Route> {
    const route = this.routesService.createRouteObject(
      null,
      routeDataForm,
      routeDataForm.controls.cityMarkerPoints as UntypedFormArray
    );

    // UPLOAD MAP
    const imageFile = routeDataForm.controls.mapFile.value
      ? routeDataForm.controls.mapFile.value.files[0]
      : null;
    if (imageFile) {
      const imagePath = CITIES_CHALLENGE_ROUTE_MAP_PATH.replace(
        'COMPANY_ID',
        this.companyId
      )
        .replace('CITIES_CHALLENGE_ID', this.citiesChallengeId)
        .replace('ROUTE_ID', String(index));
      const mapUrl = await this.routesService.uploadRouteImage(
        imageFile,
        imagePath
      );
      route.map = mapUrl;
    }
    return Promise.resolve(route);
  }

  private async generateRegions(
    regionsDataForm: FormArray<UntypedFormGroup>
  ): Promise<Array<Region>> {
    const regions = [];
    for (let i = 0; i < regionsDataForm.length; i++) {
      regions.push(
        new Region({
          route: (
            await this.generateRoute(
              regionsDataForm.at(i).controls.route as UntypedFormGroup,
              i
            )
          ).toObject(),
          duration: regionsDataForm.at(i).controls.duration.value,
          startDate: Helpers.createISOStartDateTime(
            regionsDataForm.at(i).controls.dateRange.value.start
          ),
          endDate: Helpers.createISOEndDateTime(
            regionsDataForm.at(i).controls.dateRange.value.end
          ),
        })
      );
    }
    return Promise.resolve(regions);
  }

  public async updateCitiesChallenge(): Promise<void> {
    this.showUpdatingCitiesChallengeSpinner = true;

    if (this.challengeDataForm.valid) {
      const regions = await this.generateRegions(
        this.challengeDataForm.controls.regions as UntypedFormArray
      );

      const challengeFeatures = this.challengeDataForm.controls.features.value
        ? [...this.challengeDataForm.controls.features.value]
        : [];

      this.citiesChallenge = new CitiesChallenge({
        id: this.citiesChallengeId,
        name: this.challengeDataForm.controls.name.value,
        startDate: regions[0].startDate,
        endDate: regions[regions.length - 1].endDate,
        features: [
          Array.from(this.citiesChallenge.features)[0],
          ...challengeFeatures,
        ],
        totalDistance: this.challengeDataForm.controls.totalDistance.value,
        expectedDailySteps: this.challengeDataForm.controls.expectedDailySteps
          .value,
        regions: regions.map((region) => region.toObject()),
        totalPerformance: new CitiesChallengePerformance().toObject(),
        theme: this.challengeDataForm.controls.theme.value,
      });

      try {
        await this.citiesChallengeService.createCityChallengeOnCompany(
          this.companyId,
          this.citiesChallenge
        );
      } catch (error) {
        this.showSnackBar(this.SNACKBAR_TYPES.UPDATE_FAILURE, error);
        return Promise.reject(error);
      } finally {
        this.showUpdatingCitiesChallengeSpinner = false;
      }

      this.showSnackBar(this.SNACKBAR_TYPES.UPDATE_SUCCESS);
      this.returnPage();
    }
  }
}
