import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Company, ICompanyInitializer } from '../../../../models/company.model';
import { FirebaseService } from '../../../../services/firebase/firebase.service';
import { SubscriptionService } from '../../../../services/subscription/subscription.service';
import { UserMessageService } from '../../../../services/user-message/user-message.service';
import { CompanyCitiesChallengeService } from '../../../../services/company-cities-challenge/company-cities-challenge.service';
import {
  CompanyCitiesChallenge,
  ICompanyCitiesChallengeInitializer,
} from '../../../../models/company-cities-challenge/company-cities-challenge/company-cities-challenge';
import { CompanyParticipant } from '../../../../models/company-participant/company-participant';
import { CitiesChallengeService } from '../../../../services/cities-challenge/cities-challenge.service';
import {
  CITIES_CHALLENGE_TYPES,
  CitiesChallenge,
} from '../../../../models/cities-challenge/cities-challenge/cities-challenge.model';
import { CompanyStatus } from '../../../../models/company-status.enum';
import { Region } from '../../../../models/cities-challenge/region/region.model';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-company-cities-challenge-dialog',
  templateUrl: './company-cities-challenge-dialog.component.html',
  styleUrls: ['./company-cities-challenge-dialog.component.scss'],
})
export class CompanyCitiesChallengeDialogComponent
  implements OnInit, AfterViewInit {
  public challengeForm: UntypedFormGroup;

  public citiesChallenge: CitiesChallenge;
  public participants: Array<CompanyParticipant> = [];
  private participantSubscription: () => void;
  public projectPlanStatuses: { [participantId: string]: boolean } = {};
  public editableChallenge = true;

  public CITIES_CHALLENGE_TYPES = CITIES_CHALLENGE_TYPES;

  public selectedCompany = new UntypedFormControl('');

  public companies: Array<Company> = [];
  public filteredCompanies: Observable<Array<Company>>;
  public challengeCompanies: Array<Company> = [];
  public dataSource = new MatTableDataSource(this.challengeCompanies);
  public companiesColumns: Array<string> = ['avatar', 'name', 'projectPlan'];

  public showSpinner = false;
  public sendingAllProjectPlans = false;

  public showCompanyName = (
    company?: ICompanyInitializer
  ): string | undefined => {
    if (company) {
      const companyObject = new Company(company);
      return companyObject.name;
    } else {
      return undefined;
    }
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<CompanyCitiesChallengeDialogComponent>,
    private companyCitiesChallengeService: CompanyCitiesChallengeService,
    private subscriptionService: SubscriptionService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    private firebaseService: FirebaseService,
    private citiesChallengeService: CitiesChallengeService,
    @Inject(MAT_DIALOG_DATA) public data: CompanyCitiesChallenge
  ) {
    this.subscriptionService.subscribeToCompanies();

    this.subscriptionService.companies.subscribe((companies) => {
      if (companies) {
        this.companies = companies.sort((companyA, companyB) =>
          companyA.name > companyB.name ? 1 : -1
        );
        this.filteredCompanies = of(this.companies);

        if (
          this.data &&
          this.challengeCompanies.length !== this.data.participantIds.length
        ) {
          this.challengeCompanies = this.companies.filter((company) =>
            this.data.participantIds.find(
              (companyParticipantId: string) =>
                companyParticipantId === company.id
            )
          );
          this.initTable();
        }
      }
    });
  }

  get challengeDataForm(): UntypedFormGroup {
    return this.challengeForm.get('citiesChallenge') as UntypedFormGroup;
  }

  get challengeRegions(): FormArray<FormGroup> {
    return (this.challengeForm.get('citiesChallenge') as UntypedFormGroup)
      .controls.regions as UntypedFormArray;
  }

  ngOnInit(): void {
    this.challengeForm = this.companyCitiesChallengeService.getStandardCompanyCitiesChallengeDataForm();
    this.companyCitiesChallengeService.setCompanyCitiesChallengeDataFormValues(
      this.challengeForm,
      this.data
    );
    this.citiesChallenge = this.data as CitiesChallenge;

    if (this.citiesChallenge.isPending()) {
      this.companiesColumns.push('delete');
    }

    this.editableChallenge =
      (this.challengeRegions.at(0).controls.dateRange as UntypedFormGroup)
        .controls.start.value > new Date();

    if (!this.editableChallenge) {
      this.challengeDataForm.disable({ emitEvent: false });
      if (
        (this.challengeRegions.at(this.challengeRegions.length - 1).controls
          .dateRange as UntypedFormGroup).controls.end.value > new Date()
      ) {
        this.challengeDataForm.controls.expectedDailySteps.enable({
          emitEvent: false,
        });
      }
    }

    this.participantSubscription = this.companyCitiesChallengeService.subscribeToCompanyCitiesChallengeParticipants(
      this.data.id,
      this.setParticipants.bind(this)
    );

    this.dialogRef.beforeClosed().subscribe(() => {
      this.participantSubscription();
    });

    this.selectedCompany.valueChanges.subscribe((selectedCompany: Company) => {
      if (typeof selectedCompany !== 'string') {
        if (this.challengeCompanies.indexOf(selectedCompany) < 0) {
          this.challengeCompanies.push(selectedCompany);
          this.projectPlanStatuses[selectedCompany.id] = false;
          this.initTable();
        } else {
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              'GROUP_RUN_CHALLENGE_DIALOG.COMPANY_ALREADY_IN_CHALLENGE'
            )
          );
        }

        this.selectedCompany.reset('', { emitEvent: false });
        this.filteredCompanies = of(this.filterCompanies(''));
      } else {
        this.filteredCompanies = of(this.filterCompanies(selectedCompany));
      }
    });
  }

  ngAfterViewInit(): void {
    this.initTable();
  }

  public initTable(): void {
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.challengeCompanies);

    this.initSortAndPaginator();
  }

  private initSortAndPaginator(): void {
    // Initialize sort and paginator
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Redefine the filter method
    this.dataSource.filterPredicate = (
      data: Company,
      filter: string
    ): boolean => data.name.trim().toLowerCase().indexOf(filter) !== -1;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public removeCompany(companyId: string): void {
    this.companyCitiesChallengeService
      .removeCompanyParticipant(this.citiesChallenge.id, companyId)
      .then(() => {
        this.challengeCompanies.splice(
          this.challengeCompanies.findIndex(
            (company) => company.id === companyId
          ),
          1
        );
      });
    this.initTable();
  }

  public filterCompanies(val: string): Array<Company> {
    return this.companies.filter((company) =>
      company.name.toLowerCase().includes(val.toLowerCase())
    );
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public async confirm(): Promise<void> {
    if (this.challengeForm.valid) {
      this.showSpinner = true;

      for (const company of this.challengeCompanies) {
        if (company.status === CompanyStatus.Inactive) {
          company.status = CompanyStatus.InPreparation;
          try {
            await this.firebaseService.updateCompany(company.id, {
              status: Number(company.status),
            });
          } catch (error) {
            this.userMessageService.snackBarMessage(
              this.translateService.instant(
                'COMPANY_CITIES_CHALLENGES.UPDATE_COMPANY_CITIES_CHALLENGE_RESPONSES.ERROR_SETTING_COMPANY_IN_PREPARATION',
                { companyName: company.name }
              )
            );
          }
        }

        this.companyCitiesChallengeService.updateCompanyCitiesChallengeParticipant(
          this.citiesChallenge.id,
          company.id,
          {
            id: company.id,
            avatar: company.avatar,
          }
        );
      }

      const citiesChallenge: CitiesChallenge = await this.citiesChallengeService.createCitiesChallengeObject(
        null,
        this.challengeDataForm,
        null
      );

      const companyCitiesChallengeUpdate: Partial<ICompanyCitiesChallengeInitializer> = {
        id: this.data.id,
        name: this.challengeDataForm.controls.name.value,
        expectedDailySteps: this.challengeDataForm.controls.expectedDailySteps
          .value,
        theme: this.challengeDataForm.controls.theme.value,
        participantIds: this.challengeCompanies.map(
          (company: Company) => company.id
        ),
        startDate: citiesChallenge.startDate,
        endDate: citiesChallenge.endDate,
        regions: (citiesChallenge.regions as Array<Region>).map(
          (region: Region) => region.toObject()
        ),
      };

      this.companyCitiesChallengeService
        .updateCompanyCitiesChallenge(companyCitiesChallengeUpdate)
        .then(() => {
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              citiesChallenge.id
                ? 'COMPANY_CITIES_CHALLENGE.UPDATE_COMPANY_CITIES_CHALLENGE_RESPONSES.CREATED_CORRECTLY'
                : 'COMPANY_CITIES_CHALLENGE.UPDATE_COMPANY_CITIES_CHALLENGE_RESPONSES.UPDATED_CORRECTLY',
              { name: citiesChallenge.name }
            )
          );
          this.dialogRef.close();
        })
        .catch((error) => {
          console.log('error: ', error);
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              citiesChallenge.id
                ? 'COMPANY_CITIES_CHALLENGE.UPDATE_COMPANY_CITIES_CHALLENGE_RESPONSES.ERROR_CREATING'
                : 'COMPANY_CITIES_CHALLENGE.UPDATE_COMPANY_CITIES_CHALLENGE_RESPONSES.ERROR_UPDATING',
              { name: citiesChallenge.name }
            )
          );
        })
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }

  public sendProjectPlan(company: Company, button?: MatButton): Promise<void> {
    if (button) {
      button.disabled = true;
    }
    return this.firebaseService
      .sendProjectPlan(this.citiesChallenge, company)
      .then(() => {
        const participantFound = this.data.participantIds.includes(company.id);

        if (!participantFound) {
          this.data.participantIds.push(company.id);
        }

        this.projectPlanStatuses[company.id] = true;

        this.companyCitiesChallengeService.updateCompanyCitiesChallengeParticipant(
          this.citiesChallenge.id,
          company.id,
          {
            id: company.id,
            avatar: company.avatar,
            projectPlanSent: true,
          }
        );

        this.companyCitiesChallengeService.updateCompanyCitiesChallenge({
          id: this.data.id,
          participantIds: this.data.participantIds,
        });
      })
      .finally(() => {
        if (button) {
          button.disabled = false;
        }
      });
  }

  public async sendAllProjectPlans(): Promise<void> {
    this.sendingAllProjectPlans = true;

    for (const company of this.challengeCompanies) {
      try {
        await this.sendProjectPlan(company);
      } catch (error) {
        this.userMessageService.snackBarMessage('DEFAULT_ERROR');
        console.log('error sending all project plans: ', error);
      }
    }

    this.sendingAllProjectPlans = false;
  }

  public isProjectPlanSent(companyId: string): boolean {
    return (
      this.participants.find(
        (participant: CompanyParticipant) => participant.id === companyId
      )?.projectPlanSent ?? false
    );
  }

  private setParticipants(participants: Array<CompanyParticipant>): void {
    this.participants = participants;
    this.projectPlanStatuses = this.participants.reduce(
      (accumulator, participant) => {
        accumulator[participant.id] = this.isProjectPlanSent(participant.id);
        return accumulator;
      },
      {}
    );
  }
}
