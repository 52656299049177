import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { ITheme, Theme } from '../../models/theme/theme.model';
import { UserMessageService } from '../user-message/user-message.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { REGEX_ALPHANUMERIC_EXTENDED } from 'src/app/constants/constants';
import { FirebaseConstants } from '../../models/firebase-constants.enum';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public emptyTheme: Theme = new Theme({
    id: null,
    challengeType: null,
    description: '',
    name: 'NONE',
    templates: {},
  });

  constructor(
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public getThemes(): Promise<Array<Theme>> {
    return this.firebaseService
      .getThemes()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          return [];
        }

        return querySnapshot.docs.map(
          (docSnapshot) => new Theme(docSnapshot.data())
        );
      })
      .catch((error) => {
        console.log('Error getting themes:', error);
        this.userMessageService.snackBarMessage('DEFAULT_ERROR');
        return [];
      });
  }

  public getThemesByChallengeType(
    challengeType: string
  ): Promise<Array<Theme>> {
    const availableThemes = [this.emptyTheme];

    return this.firebaseService
      .getThemesByChallengeType(challengeType)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          availableThemes.push(
            ...querySnapshot.docs.map(
              (docSnapshot) => new Theme(docSnapshot.data())
            )
          );
        }

        return availableThemes;
      })
      .catch((error) => {
        console.log('Error getting themes by challenge type:', error);
        this.userMessageService.snackBarMessage('DEFAULT_ERROR');
        return [];
      });
  }

  public removeTheme(themeId: string): Promise<void> {
    return this.firebaseService
      .removeTheme(themeId)
      .then(() =>
        this.userMessageService.snackBarMessage('THEMES.DELETE.SUCCESS')
      )
      .catch((error) => {
        console.log('Error deleting theme:', error);
        this.userMessageService.snackBarMessage('DEFAULT_ERROR');
      });
  }

  public getThemeForm(
    id: string = '',
    challengeType: string | null = '',
    description: string = '',
    name: string = '',
    langs: Array<UntypedFormGroup> = [],
    selectedLang: string = 'deu'
  ): UntypedFormGroup {
    return this.formBuilder.group({
      id: [id],
      challengeType: [challengeType, Validators.required],
      description: [
        description,
        [Validators.required, Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED)],
      ],
      name: [
        name,
        [Validators.required, Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED)],
      ],
      selectedLang: [selectedLang, Validators.required],
      langs: this.formBuilder.array(langs),
    });
  }

  public getThemeLangForm(
    langId: string = '',
    templates: Array<UntypedFormGroup> = []
  ): UntypedFormGroup {
    return this.formBuilder.group({
      lang: [langId, Validators.required],
      templates: this.formBuilder.array(templates),
    });
  }

  public getThemeTemplateForm(
    key: string = '',
    downloadUrl: string = '',
    name: string = '',
    filePath: string = ''
  ): UntypedFormGroup {
    return this.formBuilder.group({
      key: [key],
      downloadUrl: [downloadUrl, Validators.required],
      filePath: [filePath],
      fileToUpload: [null],
      name: [
        name,
        [Validators.required, Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED)],
      ],
    });
  }

  public initializeThemeForm(theme: Theme): UntypedFormGroup {
    const themeLangs: Array<UntypedFormGroup> = Object.entries(
      theme.templates
    ).map(([lang, templates]) => {
      const langTemplates = Object.entries(templates).map(([key, template]) =>
        this.getThemeTemplateForm(
          key,
          template.downloadUrl,
          template.name,
          template.filePath
        )
      );

      return this.getThemeLangForm(lang, langTemplates);
    });

    return this.getThemeForm(
      theme.id,
      theme.challengeType,
      theme.description,
      theme.name,
      themeLangs
    );
  }

  public convertThemeFormToITheme(themeForm: UntypedFormGroup): ITheme {
    return {
      id: themeForm.controls.id.value,
      challengeType: themeForm.controls.challengeType.value,
      description: themeForm.controls.description.value,
      name: themeForm.controls.name.value,
      templates: ((themeForm.controls.langs as UntypedFormArray)
        .controls as Array<UntypedFormGroup>).reduce((acc, langControl) => {
        const lang = langControl.controls.lang.value;
        const templates = ((langControl.controls.templates as UntypedFormArray)
          .controls as Array<UntypedFormGroup>).reduce(
          (accTemplates, templateControl) => {
            const key = templateControl.controls.key.value;
            const downloadUrl = templateControl.controls.downloadUrl.value;
            const name = templateControl.controls.name.value;
            const filePath = templateControl.controls.filePath.value;

            return { ...accTemplates, [key]: { downloadUrl, name, filePath } };
          },
          {}
        );

        return { ...acc, [lang]: templates };
      }, {}),
    };
  }

  public uploadThemeFiles(themeForm: UntypedFormGroup): Promise<void> {
    const langs = (themeForm.controls.langs as UntypedFormArray)
      .controls as Array<UntypedFormGroup>;
    const promises: Array<Promise<void>> = [];

    langs.forEach((langControl) => {
      const templates = (langControl.controls.templates as UntypedFormArray)
        .controls as Array<UntypedFormGroup>;

      templates.forEach((templateControl) => {
        const file = templateControl.controls.fileToUpload.value;

        if (file) {
          // eslint-disable-next-line max-len
          const filePath = `${FirebaseConstants.ThemesFolder}${themeForm.controls.id.value}/${langControl.controls.lang.value}/${templateControl.controls.key.value}`;
          promises.push(
            this.firebaseService.uploadFile(file, filePath).then(async () => {
              const downloadUrl = await this.firebaseService.getDownloadURL(
                filePath
              );
              templateControl.controls.downloadUrl.setValue(downloadUrl);
              templateControl.controls.filePath.setValue(filePath);
            })
          );
        }
      });
    });

    return promises.length === 0
      ? Promise.resolve()
      : Promise.all(promises)
          .then(() =>
            this.userMessageService.snackBarMessage('THEMES.UPLOAD.SUCCESS')
          )
          .catch((error) => {
            console.log('Error uploading file:', error);
            this.userMessageService.snackBarMessage('DEFAULT_ERROR');
          });
  }

  public async saveTheme(themeForm: UntypedFormGroup): Promise<void> {
    if (!themeForm.controls.id.value) {
      themeForm.controls.id.setValue(
        this.firebaseService.getAutomaticIdInRootCollection(
          FirebaseConstants.ThemesCollection
        )
      );
    }

    await this.uploadThemeFiles(themeForm);

    const theme = new Theme(this.convertThemeFormToITheme(themeForm));

    return this.firebaseService
      .saveTheme(theme)
      .then(() =>
        this.userMessageService.snackBarMessage('THEMES.SAVE.SUCCESS')
      )
      .catch((error) => {
        console.log('Error saving theme:', error);
        this.userMessageService.snackBarMessage('DEFAULT_ERROR');
      });
  }
}
