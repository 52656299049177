import { CHALLENGE_TYPE_NAMES, LANGUAGES_MAP } from '../../constants/constants';

export interface ITemplate {
  downloadUrl: string;
  name: string;
  filePath: string;
}

export interface ITheme {
  id: string;
  challengeType: CHALLENGE_TYPE_NAMES | null;
  description: string;
  name: string;
  templates: { [lang: string]: { [key: string]: ITemplate } };
}

export class Theme {
  public id: string = '';
  public challengeType: CHALLENGE_TYPE_NAMES | null = null;
  public description: string = '';
  public name: string = '';
  public templates: Record<string, Record<string, ITemplate>> = {
    [LANGUAGES_MAP.de]: {
      POST_1: { downloadUrl: '', filePath: '', name: '„Demnächst“' },
      POST_2: { downloadUrl: '', filePath: '', name: '„Einladung“' },
      POST_3: { downloadUrl: '', filePath: '', name: '„Challenge Übersicht“' },
      POST_4: { downloadUrl: '', filePath: '', name: '„Duelle“' },
      POST_5: { downloadUrl: '', filePath: '', name: '„Challenge Zugang“' },
      POST_6: { downloadUrl: '', filePath: '', name: '„Es geht los“' },
      REPORT: { downloadUrl: '', filePath: '', name: '„Bericht“' },
    },
  };

  constructor(initializer?: Partial<ITheme>) {
    if (initializer) {
      this.id = initializer.id ?? this.id;
      this.challengeType = initializer.challengeType ?? this.challengeType;
      this.description = initializer.description ?? this.description;
      this.name = initializer.name ?? this.name;
      this.templates = initializer.templates ?? this.templates;
    }
  }

  public toObject(): ITheme {
    return {
      id: this.id,
      challengeType: this.challengeType,
      description: this.description,
      name: this.name,
      templates: this.templates,
    };
  }
}
