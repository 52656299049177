<div class="container">
  <section class="buttons">
    <button mat-raised-button (click)="openNewThemeDialog()">
      {{ 'THEMES.BUTTON_LABEL' | translate }}
      <mat-icon>add</mat-icon>
    </button>
  </section>
  
  <section class="table" *ngIf="!showSpinner">
    <div class="filter-container">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'THEMES.FILTER.LABEL' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="'THEMES.FILTER.PLACEHOLDER' | translate " #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <table
      mat-table
      [dataSource]="themesDataSource"
      matSort
    >

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'THEMES.COLUM.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let theme">
          {{ theme.name }}
        </td>
      </ng-container>

       <!-- Challenge type Column -->
      <ng-container matColumnDef="challengeType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'THEMES.COLUM.CHALLENGE_TYPE' | translate}}</th>
        <td mat-cell *matCellDef="let theme">
          {{ theme.challengeType }}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'THEMES.COLUM.DESCRIPTION' | translate}}</th>
        <td mat-cell *matCellDef="let theme">
          {{ theme.description }}
        </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let theme" (click)="openNewThemeDialog(theme)">
          <mat-icon>edit_outline</mat-icon>
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let theme" (click)="removeTheme(theme)">
          <mat-icon>delete_outline</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="themeColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: themeColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">{{ 'THEMES.FILTER.EMPTY_MESSAGE' | translate}} "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator pageSize="10" aria-label="Themes table"></mat-paginator>
  </section>

  <section class="spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </section>
</div>