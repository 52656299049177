<div mat-dialog-title class="dialog-title">
  <div class="top">
    <h1 class="title">{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.TITLE' | translate }}</h1>
  </div>
</div>
<div mat-dialog-content
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
  class="dialog-content"
>
  <app-cities-challenge-form
    [citiesChallengeDataForm]="challengeDataForm"
    [editableChallenge]="editableChallenge"
    [editableRoute]="editableChallenge && (citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[1]) || citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[2]))"
    [createRegions]="citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[2])"
  ></app-cities-challenge-form>
  <section>
    <h2>{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.COMPANIES_SUBTITLE' | translate }}</h2>
    <mat-form-field>
      <mat-label>{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.FORM.companySelector.LABEL' | translate }}</mat-label>
      <input type="text" matInput [matAutocomplete]="companiesAutocomplete" [formControl]="selectedCompany" />
      <mat-autocomplete #companiesAutocomplete="matAutocomplete" [displayWith]="showCompanyName">
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          <img class="option-logo" height="24px" width="32px" [src]="company.avatar" />
          {{ company.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint align="start">{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.FORM.companySelector.HINT' | translate}}</mat-hint>
    </mat-form-field>
  </section>
  <section *ngIf="challengeCompanies.length !== 0">
    <div class="filter-container">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.TABLE.FILTER.LABEL' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="'COMPANY_CITIES_CHALLENGE.DIALOG.TABLE.FILTER.PLACEHOLDER' | translate " #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <button mat-raised-button (click)="sendAllProjectPlans()" [disabled]="sendingAllProjectPlans">
        {{ 'COMPANY_CITIES_CHALLENGE.DIALOG.SEND_ALL_PROJECT_PLANS_BUTTON' | translate }}
      </button>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
    >
      <!-- Id Column -->
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let company" class="avatar">
          <img
            *ngIf="company.avatar !== 'assets/img/move-me-new.png'"
            src="{{ company.avatar }}"
            alt="avatar"
          />
          <div *ngIf="company.avatar === 'assets/img/move-me-new.png'">
            <mat-icon>do_not_disturb</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.TABLE.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let company">
          {{ company.name }}
        </td>
      </ng-container>

      <!-- Project Plan Column -->
      <ng-container matColumnDef="projectPlan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let company">
          <button mat-raised-button #projectPlanButton [disabled]="sendingAllProjectPlans" (click)="sendProjectPlan(company, projectPlanButton)" [ngStyle]="projectPlanStatuses[company.id] ? {'background-color': 'var(--color-success)', 'color': 'var(--color-white)'} : {}">
            <mat-spinner diameter="20" *ngIf="projectPlanButton.disabled"></mat-spinner>
            <mat-icon *ngIf="!projectPlanButton.disabled">{{ projectPlanStatuses[company.id] ? 'mark_email_read' : 'outgoing_mail' }}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let company" (click)="removeCompany(company.id)">
          <mat-icon>delete_outline</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="companiesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: companiesColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">{{ 'COMPANY_CITIES_CHALLENGE.DIALOG.TABLE.FILTER.MESSAGE' | translate}} "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator pageSize="5" aria-label="Select companies page"></mat-paginator>
  </section>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
  <button mat-button [disabled]="!challengeForm.valid || showSpinner" (click)="confirm()">
    {{ 'CONFIRM' | translate }}
    <mat-spinner [diameter]="16" *ngIf="showSpinner"></mat-spinner>
  </button>
</div>
