import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  CHALLENGE_TYPE_NAMES,
  LANGUAGES_MAP,
} from '../../../constants/constants';
import { ThemeService } from '../../../services/theme/theme.service';
import { Theme } from '../../../models/theme/theme.model';
import { Helpers } from '../../../helpers/helpers';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-theme-dialog',
  templateUrl: './theme-dialog.component.html',
  styleUrls: ['./theme-dialog.component.scss'],
})
export class ThemeDialogComponent implements OnInit, AfterViewInit {
  public themeForm: UntypedFormGroup;
  public showSpinner = false;
  public challengeTypes = Object.keys(CHALLENGE_TYPE_NAMES);
  public dataSource: MatTableDataSource<UntypedFormGroup> = new MatTableDataSource();
  public columns: Array<string> = ['key', 'name', 'upload', 'view'];

  public LANGUAGES_MAP = LANGUAGES_MAP;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Theme,
    private themeService: ThemeService,
    public dialogRef: MatDialogRef<ThemeDialogComponent>
  ) {}

  ngOnInit(): void {
    this.themeForm = this.themeService.initializeThemeForm(this.data);
    this.initTable();
  }

  ngAfterViewInit(): void {
    this.initSortAndPaginator();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public initTable(): void {
    const langs = this.themeForm.get('langs') as UntypedFormArray;
    const selectedLang = this.themeForm.get('selectedLang')?.value;
    const langControl = (langs.controls as Array<UntypedFormGroup>).find(
      (control) => control.get('lang')?.value === selectedLang
    );
    const templates = langControl?.get('templates') as UntypedFormArray;

    (templates.controls as Array<UntypedFormGroup>).sort((controlA, controlB) =>
      controlA.value.key > controlB.value.key ? 1 : -1
    );

    this.dataSource = new MatTableDataSource(
      templates.controls as Array<UntypedFormGroup>
    );

    // Redefine the filter method
    this.dataSource.filterPredicate = (
      data: UntypedFormGroup,
      filter: string
    ): boolean =>
      data.controls.name.value.trim().toLowerCase().indexOf(filter) !== -1 ||
      data.controls.key.value.trim().toLowerCase().indexOf(filter) !== -1;

    // Redefine the sort accessor method
    this.dataSource.sortingDataAccessor = (
      data: UntypedFormGroup,
      sortHeaderId: string
    ): string | number => data.controls[sortHeaderId].value;

    this.initSortAndPaginator();
  }

  private initSortAndPaginator(): void {
    // Initialize sort and paginator
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public openFile(downloadUrl: string): void {
    window.open(downloadUrl, '_blank');
  }

  public confirm(): Promise<void> {
    this.showSpinner = true;
    return this.themeService
      .saveTheme(this.themeForm)
      .then(() => {
        this.dialogRef.close();
      })
      .finally(() => (this.showSpinner = false));
  }

  public onFileChange(event: Event, form: UntypedFormGroup): void {
    const eventTarget = event.target as HTMLInputElement;
    const files = eventTarget.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      form.controls.downloadUrl.setValue(_file);
      form.controls.fileToUpload.setValue(files[0]);
    }
  }
}
