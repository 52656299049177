export interface IParticipantPerformanceInitializer {
  id: string;
  avatar: string;
  name: string;
  performance: number;
  steps: number;
  mediumIntensityMinutes: number;
  highIntensityMinutes: number;
  count: number;
  bikeKm: number;
  syncs: number;
}

export class ParticipantPerformance {
  public id: string = '';
  public avatar: string = '';
  public name: string = '';
  public performance: number = 0;
  public steps: number = 0;
  public mediumIntensityMinutes: number = 0;
  public highIntensityMinutes: number = 0;
  public count: number = 0;
  public bikeKm: number = 0;
  public syncs: number = 0;

  constructor(initializer?: Partial<IParticipantPerformanceInitializer>) {
    if (initializer) {
      this.id = initializer.id ?? this.id;
      this.avatar = initializer.avatar ?? this.avatar;
      this.name = initializer.name ?? this.name;
      this.performance = initializer.performance ?? this.performance;
      this.steps = initializer.steps ?? this.steps;
      this.mediumIntensityMinutes =
        initializer.mediumIntensityMinutes ?? this.mediumIntensityMinutes;
      this.highIntensityMinutes =
        initializer.highIntensityMinutes ?? this.highIntensityMinutes;
      this.count = initializer.count ?? this.count;
      this.bikeKm = initializer.bikeKm ?? this.bikeKm;
      this.syncs = initializer.syncs ?? this.syncs;
    }
  }

  public toObject(): IParticipantPerformanceInitializer {
    return Object.assign({}, this);
  }
}
