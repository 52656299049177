import { Injectable } from '@angular/core';
import { PDFDocument, PDFFont, PDFForm, PDFImage } from 'pdf-lib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as fontkit from '@btielen/pdf-lib-fontkit';

// Models
import { DistanceChallenge } from 'src/app/models/distance-challenge.model';
import { Company } from 'src/app/models/company.model';
import { Report } from 'src/app/models/reports/report.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { ReportChallenge } from 'src/app/models/reports/report-challenge.model';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { CitiesChallengeDayPerformance } from 'src/app/models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from '../teams/teams.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

// Constants
import { ONE_PAGER } from 'src/app/constants/constants';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { ReportTypes } from 'src/app/models/reports/report-types.enum';
import { IWeeklyHistory } from 'src/app/models/team-challenge/weekly-history/weekly-history.model';

export class BlobFile {
  title: string;
  blob: Blob;
  status: ReportStatus;
  fileURL?: SafeResourceUrl;
}

export enum ReportStatus {
  Pending,
  Uploading,
  Saved,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public reportTranslations = null;

  constructor(
    private firebaseService: FirebaseService,
    private companyService: CompanyService,
    private teamsService: TeamsService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  public async generateTeamChallengeReport(
    company: Company,
    teamChallenge: TeamChallenge,
    weeklyHistory: IWeeklyHistory
  ): Promise<string> {
    const result = await this.firebaseService.generateTeamChallengeReport(
      teamChallenge,
      weeklyHistory,
      company
    );

    return result.data.downloadUrl;
  }

  public async generateTeamOnePagerReport(
    company: Company,
    documentTitle: string,
    challenge: TeamChallenge | DistanceChallenge,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reportTranslations: any,
    templatePath: string
  ): Promise<Array<BlobFile>> {
    const blobFiles: Array<BlobFile> = [];
    const templateUrl = await this.firebaseService.getDownloadURL(templatePath);

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(templatePdfBytes);
    const form = pdfDoc.getForm();

    const teamChallenge: TeamChallenge = new TeamChallenge(challenge);
    const companyTeams = await this.teamsService.getTeams(company.id);

    if (company.id !== 'BBRAUN') {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.COMPANY_LOGO,
        company.avatar,
        form,
        pdfDoc
      );
    }

    if (company.whiteLabel) {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.WHITE_LABEL_LOGO,
        company.whiteLabel.logo,
        form,
        pdfDoc
      );
    }

    // Embed fonts
    pdfDoc.registerFontkit(fontkit);
    const openSansExtraBoldItalic = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBoldItalic,
      pdfDoc
    );
    const openSansRegular = await this.embedCustomFont(
      FirebaseConstants.OpenSansRegular,
      pdfDoc
    );
    const openSansBold = await this.embedCustomFont(
      FirebaseConstants.OpenSansBold,
      pdfDoc
    );

    // Complete text fields
    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME,
      challenge.name,
      form,
      openSansExtraBoldItalic
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_START_DATE,
      Helpers.formatDateByLanguage(
        new Date(challenge.startDate),
        this.translateService.currentLang
      ),
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME_TEXT,
      challenge.name,
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CODE_TYPE,
      reportTranslations.ONE_PAGER.COMPANY_CODE,
      form,
      openSansBold
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.COMPANY_ID,
      company.id,
      form,
      openSansBold
    );

    for (const teamId of teamChallenge.teamIds) {
      const team = companyTeams.find(
        (companyTeam) => companyTeam.id === teamId
      );
      const teamName = team ? team.name : teamId;
      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.TEAM_NAME,
        reportTranslations.ONE_PAGER.TEAM_PREFIX + teamName,
        form,
        openSansExtraBoldItalic
      );

      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.CODE_TYPE_TEAM,
        reportTranslations.ONE_PAGER.TEAM_CODE,
        form,
        openSansBold
      );

      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.TEAM_ID,
        teamId,
        form,
        openSansBold
      );

      // save team report
      blobFiles.push(
        await this.generateBlobFile(pdfDoc, form, teamId + '-' + documentTitle)
      );
    }

    return blobFiles;
  }

  public async generateCompanyOnePagerReport(
    company: Company,
    documentTitle: string,
    challenge: TeamChallenge | DistanceChallenge,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reportTranslations: any
  ): Promise<Array<BlobFile>> {
    const blobFiles: Array<BlobFile> = [];
    const templateUrl = await this.firebaseService.getDownloadURL(
      ONE_PAGER.TEMPLATE_PATH
    );

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(templatePdfBytes);
    const form = pdfDoc.getForm();

    if (company.id !== 'BBRAUN') {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.COMPANY_LOGO,
        company.avatar,
        form,
        pdfDoc
      );
    }

    if (company.whiteLabel) {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.WHITE_LABEL_LOGO,
        company.whiteLabel.logo,
        form,
        pdfDoc
      );
    }

    // Embed fonts
    pdfDoc.registerFontkit(fontkit);
    const openSansExtraBoldItalic = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBoldItalic,
      pdfDoc
    );
    const openSansRegular = await this.embedCustomFont(
      FirebaseConstants.OpenSansRegular,
      pdfDoc
    );
    const openSansBold = await this.embedCustomFont(
      FirebaseConstants.OpenSansBold,
      pdfDoc
    );

    // Complete text fields
    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME,
      challenge.name,
      form,
      openSansExtraBoldItalic
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_START_DATE,
      Helpers.formatDateByLanguage(
        new Date(challenge.startDate),
        this.translateService.currentLang
      ),
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME_TEXT,
      challenge.name,
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CODE_TYPE,
      reportTranslations.ONE_PAGER.COMPANY_CODE,
      form,
      openSansBold
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.COMPANY_ID,
      company.id,
      form,
      openSansBold
    );

    // save company report
    blobFiles.push(await this.generateBlobFile(pdfDoc, form, documentTitle));

    return blobFiles;
  }

  public async generateBlobFile(
    pdfDoc: PDFDocument,
    form: PDFForm,
    documentTitle: string
  ): Promise<BlobFile> {
    // Set all fields as read only
    form.getFields().map((field) => field.enableReadOnly());

    // Set document title
    pdfDoc.setTitle(documentTitle);

    const pdfToSave: PDFDocument = await pdfDoc.copy();
    // save and create a Blob
    const pdfBytes = await pdfToSave.save();
    const blob: Blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const blobFile: BlobFile = {
      title: documentTitle,
      blob,
      status: ReportStatus.Pending,
      fileURL: this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(blob)
      ),
    };

    return blobFile;
  }

  public openBlob(blob: Blob): void {
    const file = new File([blob], 'txtName.pdf', { type: 'application/pdf' });
    const reportUrl = URL.createObjectURL(file);
    window.open(reportUrl);
  }

  public async embedCustomFont(
    fontName: string,
    pdfDoc: PDFDocument
  ): Promise<PDFFont> {
    const fontUrl = await this.firebaseService.getDownloadURL(
      FirebaseConstants.TemplatesFolder +
        FirebaseConstants.FontsFolder +
        fontName
    );
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    const font = await pdfDoc.embedFont(fontBytes);
    return font;
  }

  public async downloadAndSetImageField(
    fieldName: string,
    imageUrl: string,
    form: PDFForm,
    pdfDoc: PDFDocument
  ): Promise<void> {
    const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
    let image: PDFImage;
    try {
      image = await pdfDoc.embedPng(imageBytes);
    } catch {
      image = await pdfDoc.embedJpg(imageBytes);
    }
    const imageField = form.getButton(fieldName);
    imageField.setImage(image);
  }

  public setTextField(
    fieldName: string,
    fieldValue: string,
    form: PDFForm,
    font?: PDFFont
  ): void {
    const companyNameField = form.getTextField(fieldName);
    companyNameField.setText(fieldValue);
    companyNameField.updateAppearances(font);
  }

  public releaseReport(
    report: Report,
    reportId: string,
    companyId: string
  ): Promise<void> {
    return this.firebaseService.addNewReport(report, reportId, companyId);
  }

  public async uploadReport(
    fileToUpload: Blob,
    documentTitle: string,
    type: ReportTypes,
    companyId: string,
    challengeId: string
  ): Promise<Report> {
    let longReportUrl = '';
    let shortReportUrl = '';
    try {
      const snapshot = await this.firebaseService.uploadReport(
        fileToUpload,
        documentTitle,
        companyId
      );
      longReportUrl = await snapshot.ref.getDownloadURL();
      shortReportUrl = await this.firebaseService.createDynamicLink(
        longReportUrl
      );
    } catch (error) {
      console.log('uploadReport - error: ', error);
    }
    if (longReportUrl !== '' && shortReportUrl !== '') {
      // TODO: update when a new report type does not need challenge id
      return new ReportChallenge({
        name: documentTitle,
        src: longReportUrl,
        shortLink: shortReportUrl,
        type,
        dateGenerated: Date.now(),
        challengeId,
      });
    } else {
      return Promise.reject('Could not upload report');
    }
  }

  public reportExist(path: string): Promise<boolean> {
    return this.firebaseService.fileExist(path);
  }

  public async generateCitiesChallengeReport(
    citiesChallenge: CitiesChallenge,
    citiesChallengeDayPerformance: CitiesChallengeDayPerformance,
    companyId: string
  ): Promise<string> {
    // Get company
    const company = await this.companyService.getCompany(companyId);

    const result = await this.firebaseService.generateCitiesChallengeReport(
      citiesChallenge,
      citiesChallengeDayPerformance,
      company
    );

    return result.data.downloadUrl;
  }
}
