<ng-container *ngIf="teamChallengeDataForm">
  <div class="save-button-container" *ngIf="showSaveButton">
    <button mat-raised-button color="primary" [disabled]="!teamChallengeDataForm.dirty || !teamChallengeDataForm.valid || showSavingSpinner" class="save-button" (click)="saveTeamChallenge()">
      {{ 'TEAM_CHALLENGE_FORM.SAVE_TEAM_CHALLENGE_BUTTON.LABEL' | translate }}
      <mat-spinner *ngIf="showSavingSpinner" diameter="20"></mat-spinner>
    </button>
  </div>
  <form class="challenge-form" [formGroup]="teamChallengeDataForm">
    <mat-form-field>
      <mat-label>{{ 'TEAM_CHALLENGE_FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ getFormErrors(teamChallengeDataForm.controls.name, 'TEAM_CHALLENGE_FORM', 'name') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field class="duration-field">
      <mat-label>{{ 'TEAM_CHALLENGE_FORM.durationInWeeks.LABEL' | translate }}</mat-label>
      <input required matInput type="number" matInput formControlName="durationInWeeks" />
      <mat-hint>{{ 'TEAM_CHALLENGE_FORM.durationInWeeks.HINT' | translate }}</mat-hint>
      <mat-error>{{ getFormErrors(teamChallengeDataForm.controls.durationInWeeks, 'TEAM_CHALLENGE_FORM', 'durationInWeeks') | translate }} </mat-error>
    </mat-form-field>

    <div class="date-range-container">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAM_CHALLENGE_FORM.dateRange.startDate.LABEL' | translate}}</mat-label>
        <input required  matInput [matDatepicker]="teamChallengeStartDatePicker" [min]="minDate" [formControl]="teamChallengeStartDate" (click)="teamChallengeStartDatePicker.open()">
        <mat-datepicker-toggle matSuffix [for]="teamChallengeStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #teamChallengeStartDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'TEAM_CHALLENGE_FORM.dateRange.endDate.LABEL' | translate}}</mat-label>
        <input matInput [matDatepicker]="teamChallengeEndDatePicker" [formControl]="teamChallengeEndDate">
        <mat-datepicker-toggle matSuffix [for]="teamChallengeEndDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #teamChallengeEndDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>{{ 'TEAM_CHALLENGE_FORM.maxParticipantsPerTeam.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="maxParticipantsPerTeam" />
      <mat-error>{{ getFormErrors(teamChallengeDataForm.controls.maxParticipantsPerTeam, 'TEAM_CHALLENGE_FORM', 'maxParticipantsPerTeam') | translate }} </mat-error>
      <mat-hint align="start">{{ 'TEAM_CHALLENGE_FORM.maxParticipantsPerTeam.HINT' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'TEAM_CHALLENGE_FORM.theme.LABEL' | translate }}</mat-label>
      <mat-select formControlName="theme">
        <mat-option *ngFor="let theme of availableThemes" [value]="theme.id">
          {{ theme.name | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="features.includes(TEAM_CHALLENGE_FEATURES.LOCATIONS)">
      <mat-label>{{ 'TEAM_CHALLENGE_FORM.locationLevel.LABEL' | translate }}</mat-label>
      <mat-select formControlName="locationLevel" [placeholder]="'Set location level' | translate">
        <mat-option *ngFor="let locationLevel of availableLocationLevels" [value]="locationLevel.hierarchyLevel">
          {{ locationLevel.hierarchyLevel }} {{ locationLevel.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="add-teams-form" *ngIf="features.includes(TEAM_CHALLENGE_FEATURES.TEAM_SELECTION)">
      <mat-form-field>
        <mat-label>{{ 'TEAM_CHALLENGE_FORM.teamsAmount.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="teamsAmount" />
        <mat-error>{{ getFormErrors(teamChallengeDataForm.controls.teamsAmount, 'TEAM_CHALLENGE_FORM', 'teamsAmount') | translate }} </mat-error>
      </mat-form-field>
      <button class="add-teams-button" mat-raised-button color="primary"
        [disabled]="teamChallengeDataForm.controls.teamsAmount.value <= 0"
        (click)="addRandomTeams()"
      >
        {{ 'TEAM_CHALLENGE_FORM.ADD_TEAMS_BUTTON.LABEL' | translate }}
      </button>
    </div>
  </form>

  <mat-spinner *ngIf="addingTeams"></mat-spinner>
  <app-teams-table *ngIf="teamForms.length > 0 && features.includes(TEAM_CHALLENGE_FEATURES.TEAM_SELECTION)" [teamsTableElement]="teamTableElement" [teamForms]="teamForms"></app-teams-table>
</ng-container>
