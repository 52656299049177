import { Injectable } from '@angular/core';
import {
  CompanyCitiesChallenge,
  ICompanyCitiesChallengeInitializer,
} from '../../models/company-cities-challenge/company-cities-challenge/company-cities-challenge';
import { FirebaseService } from '../firebase/firebase.service';
import { UserMessageService } from '../user-message/user-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CitiesChallengeService } from '../cities-challenge/cities-challenge.service';
import { CitiesChallenge } from '../../models/cities-challenge/cities-challenge/cities-challenge.model';
import { FirebaseConstants } from '../../models/firebase-constants.enum';
import {
  CompanyParticipant,
  ICompanyParticipant,
} from '../../models/company-participant/company-participant';

@Injectable({
  providedIn: 'root',
})
export class CompanyCitiesChallengeService {
  public companyCitiesChallenges: Array<CompanyCitiesChallenge> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    private citiesChallengeService: CitiesChallengeService
  ) {}

  public getStandardCompanyCitiesChallengeDataForm(): UntypedFormGroup {
    const citiesChallengeFormGroup = this.citiesChallengeService.getStandardCitiesChallengeDataForm();
    return this.formBuilder.group({
      citiesChallenge: citiesChallengeFormGroup,
      participants: [[]],
    });
  }

  public setCompanyCitiesChallengeDataFormValues(
    companyCitiesChallengeDataForm: UntypedFormGroup,
    companyCitiesChallenge: CompanyCitiesChallenge
  ): void {
    this.citiesChallengeService.setCitiesChallengeDataFormValues(
      companyCitiesChallengeDataForm.get('citiesChallenge') as UntypedFormGroup,
      companyCitiesChallenge
    );
  }

  public getCompanyCitiesChallenges(): Promise<Array<CompanyCitiesChallenge>> {
    return this.firebaseService
      .getCompanyCitiesChallenges()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.companyCitiesChallenges = querySnapshot.docs.map(
            (docSnapshot) =>
              new CompanyCitiesChallenge(
                docSnapshot.data() as ICompanyCitiesChallengeInitializer
              )
          );
          return [...this.companyCitiesChallenges];
        } else {
          this.companyCitiesChallenges = [];
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get group run challenges error: ${error}`);
        this.userMessageService.snackBarMessage(
          this.translateService.instant('DEFAULT_ERROR')
        );
        this.companyCitiesChallenges = [];
        return [];
      });
  }

  public createCompanyCitiesChallenge(
    challenge: CitiesChallenge
  ): Promise<void> {
    if (!challenge.id) {
      challenge.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.CompanyCitiesChallengesCollection
      );
    }

    const companyCitiesChallenge = new CompanyCitiesChallenge(
      challenge.toObject()
    );

    return this.firebaseService.setCompanyCitiesChallenge(
      companyCitiesChallenge
    );
  }

  public updateCompanyCitiesChallenge(
    changes: Partial<ICompanyCitiesChallengeInitializer>
  ): Promise<void> {
    return this.firebaseService.updateCompanyCitiesChallenge(changes);
  }

  public subscribeToCompanyCitiesChallengeParticipants(
    challengeId: string,
    setParticipantsFunction: (participants: Array<CompanyParticipant>) => void
  ): () => void {
    return this.firebaseService.subscribeToCompanyCitiesChallengeParticipants(
      challengeId,
      {
        next: (querySnapshot) => {
          setParticipantsFunction(
            querySnapshot.docs.map(
              (docSnapshot) =>
                new CompanyParticipant(
                  docSnapshot.data() as ICompanyParticipant
                )
            )
          );
        },
        error: (error) => {
          console.log(
            `Get company cities challenge participants error: ${error}`
          );
          this.userMessageService.snackBarMessage(
            this.translateService.instant('DEFAULT_ERROR')
          );
          setParticipantsFunction([]);
        },
      }
    );
  }

  public updateCompanyCitiesChallengeParticipant(
    challengeId: string,
    participantId: string,
    update: Partial<ICompanyParticipant>
  ): Promise<void> {
    return this.firebaseService
      .updateCompanyCitiesChallengeParticipant(
        challengeId,
        participantId,
        update
      )
      .catch((error) => {
        console.log(
          `update company cities challenge participant error: ${error}`
        );
        this.userMessageService.snackBarMessage(
          this.translateService.instant('DEFAULT_ERROR')
        );
      });
  }

  public removeCompanyParticipant(
    challengeId: string,
    companyId: string
  ): Promise<void> {
    return this.firebaseService.removeCompanyCitiesChallengeParticipant(
      challengeId,
      companyId
    );
  }
}
