<div mat-dialog-title class="dialog-title">
  <div class="top">
    <h1 class="title">{{ 'THEMES.TITLE' | translate }}</h1>
  </div>
  <div mat-dialog-content
    fxLayout.xs="column space-between"
    fxLayout.sm="column space-between"
    class="dialog-content"
  >
    <form [formGroup]="themeForm">
      <mat-form-field>
        <mat-label>{{ 'THEMES.FORM.challengeType.LABEL' | translate }}</mat-label>
        <mat-select formControlName="challengeType">
          <mat-option *ngFor="let challengeType of challengeTypes" [value]="challengeType">
            {{ challengeType }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFormErrors(themeForm.controls.challengeType, 'THEMES.FORM', 'challengeType') | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'THEMES.FORM.name.LABEL' | translate }}</mat-label>
        <input matInput formControlName="name" />
        <mat-error>{{ getFormErrors(themeForm.controls.name, 'THEMES.FORM', 'name') | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'THEMES.FORM.description.LABEL' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
        <mat-error>{{ getFormErrors(themeForm.controls.description, 'THEMES.FORM', 'description') | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'THEMES.FORM.lang.LABEL' | translate }}</mat-label>
        <mat-select formControlName="selectedLang">
          <mat-option *ngFor="let availableLang of LANGUAGES_MAP | keyvalue" [value]="availableLang.value">
            {{ ('languages.' + availableLang.key) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <section class="templates-table" *ngIf="dataSource">
        <mat-form-field>
          <mat-label>{{ 'THEMES.TEMPLATE_FILTER.LABEL' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" [placeholder]="'THEMES.TEMPLATE_FILTER.PLACEHOLDER' | translate " #input>
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>

            <!-- Template id Column -->
            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'THEMES.TEMPLATE_COLUMNS.ID' | translate }} </th>
              <td mat-cell *matCellDef="let templateForm; let i = index">
                {{ templateForm.get('key').value }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'THEMES.TEMPLATE_COLUMNS.NAME' | translate }} </th>
              <td mat-cell *matCellDef="let templateForm">
                <mat-form-field [hideRequiredMarker]="true">
                  <mat-label></mat-label>
                  <input matInput type="text" [formControl]="templateForm.get('name')" placeholder="Name" />
                  <mat-error>{{ getFormErrors(templateForm.controls.name, 'THEMES.FORM.TEMPLATE', 'name') | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Upload Column -->
            <ng-container matColumnDef="upload">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let templateForm; let i = index">
                <input #inputUploadFile hidden="true" type="file" [id]="'avatar-input-file'+i" accept=".pdf" (change)="onFileChange($event, templateForm)" onclick="this.value=null"/>
                <mat-icon class="action-icon" (click)="inputUploadFile.click()">cloud_upload</mat-icon>
              </td>
            </ng-container>

            <!-- View Column -->
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let templateForm">
                <mat-icon
                  [ngStyle]="{ 'color': templateForm.get('downloadUrl').value ? 'var(--color-primary)' : '' }"
                  (click)="templateForm.get('downloadUrl').value ? openFile(templateForm.controls.downloadUrl.value) : ''"
                >
                  file_open
                </mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">{{ 'THEMES.TEMPLATE_FILTER.EMPTY_MESSAGE' | translate}} "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator [pageSizeOptions]="[7, 10, 25, 100]" aria-label="Select page of templates"></mat-paginator>
        </div>
      </section>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button mat-button [disabled]="!themeForm?.valid || showSpinner" (click)="confirm()">
      {{ 'CONFIRM' | translate }}
      <mat-spinner [diameter]="16" *ngIf="showSpinner"></mat-spinner>
    </button>
  </div>
</div>
