import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Theme } from 'src/app/models/theme/theme.model';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
import { MatDialog } from '@angular/material/dialog';
import { ThemeDialogComponent } from '../theme-dialog/theme-dialog.component';

@Component({
  selector: 'app-themes-tab',
  templateUrl: './themes-tab.component.html',
  styleUrls: ['./themes-tab.component.scss'],
})
export class ThemesTabComponent implements OnInit {
  public showSpinner = true;
  public themesDataSource = new MatTableDataSource();
  public themeColumns: Array<string> = [
    'name',
    'challengeType',
    'description',
    'edit',
    'delete',
  ];

  constructor(
    private themeService: ThemeService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadDataSource();
  }

  public openNewThemeDialog(theme: Theme = new Theme()): void {
    const groupRunChallengeRef = this.dialog.open(ThemeDialogComponent, {
      data: theme,
      width: '1000px',
    });

    groupRunChallengeRef.afterClosed().subscribe(() => {
      this.loadDataSource();
    });
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.themesDataSource.filter = filterValue.trim().toLowerCase();

    if (this.themesDataSource.paginator) {
      this.themesDataSource.paginator.firstPage();
    }
  }

  public loadDataSource(): void {
    this.showSpinner = true;
    this.themeService
      .getThemes()
      .then((themes) => {
        this.themesDataSource = new MatTableDataSource(themes);
      })
      .finally(() => (this.showSpinner = false));
  }

  public async removeTheme(theme: Theme): Promise<void> {
    this.userMessageService.openConfirmationDialog(
      new ConfirmationDialogData({
        title: this.translateService.instant('THEMES.ALERT.TITLE', {
          name: theme.name,
        }),
        message: this.translateService.instant('THEMES.ALERT.MESSAGE'),
        showSpinner: true,
        action: () =>
          this.themeService
            .removeTheme(theme.id)
            .then(() => this.loadDataSource()),
      })
    );
  }
}
